import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'
import '../styles/perspectives-box.scss'

const PerspectivesBox = () => {

    const data = useStaticQuery(graphql`
        query PerspectivesBlockQuery {
            wp {
                resourcesGeneralSettings {
                    resourceLibraryHeader {
                        engageBlockText
                        learnBlockText
                        loveBlockText
                        engageBlockBackground {
                            mediaDetails {
                                sizes {
                                    sourceUrl
                                    name
                                }
                            }
                        }
                        learnBlockBackground {
                            mediaDetails {
                                sizes {
                                    sourceUrl
                                    name
                                }
                            }
                        }
                        loveBlockBackground {
                            mediaDetails {
                                sizes {
                                    sourceUrl
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    //console.log('data is: ', data)
    const settings = data.wp.resourcesGeneralSettings.resourceLibraryHeader
    return (
        <section className="perspectives-box container">
            <a href="/resources/perspectives/learn"
               className="perspectives-box__child"
               style={{backgroundImage: `url(${settings.learnBlockBackground.mediaDetails?.sizes.find(size => size.name === 'medium')?.sourceUrl})`}}
            >
                <span className="h3">Learn</span>
                <p>{settings.learnBlockText}</p>
            </a>
            <a href="/resources/perspectives/engage"
               className="perspectives-box__child"
               style={{backgroundImage: `url(${settings.engageBlockBackground.mediaDetails?.sizes.find(size => size.name === 'medium')?.sourceUrl})`}}
            >
                <span className="h3">Engage</span>
                <p>{settings.engageBlockText}</p>
            </a>
            <a href="/resources/perspectives/love"
               className="perspectives-box__child"
               style={{backgroundImage: `url(${settings.loveBlockBackground.mediaDetails?.sizes.find(size => size.name === 'medium')?.sourceUrl})`}}
            >
                <span className="h3">Love</span>
                <p>{settings.loveBlockText}</p>
            </a>
        </section>
    )
}

export default PerspectivesBox