import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons'
import {faExternalLink} from '@fortawesome/pro-solid-svg-icons'
import {graphql, useStaticQuery} from 'gatsby'
import '../styles/browse-box.scss'

const BrowseBox = (props) => {
    const data = useStaticQuery(graphql`
        query PopularTopicQuery {
            allWpTopic(filter: {popularity: {popular: {eq: true}}}) {
                edges {
                    node {
                        id
                        slug
                        name
                    }
                }
            }
        }
    `)

    return (
        <section className="browse-box">
            <h4>Find What You're Looking For</h4>
            <ul className="browse-box__index-list">
                <li><a href="/resources/topics">Topic Index <span><FontAwesomeIcon icon={faExternalLink} /></span></a></li>
                <li><a href="/resources/authors">Author Index <span><FontAwesomeIcon icon={faExternalLink} /></span></a></li>
                <li><a href="/resources/scripture">Scripture Index <span><FontAwesomeIcon icon={faExternalLink} /></span></a></li>
                <li><a href="/resources/series">Series Index <span><FontAwesomeIcon icon={faExternalLink} /></span></a></li>
            </ul>
            <h4>Browse Popular Topics in the Library: </h4>
            <ul className="browse-box__topics-list">
                {data.allWpTopic.edges.map((topic, index) =>
                    <li className="h5"><a href={`/resources/topics/${topic.node.slug}`}>{topic.node.name} <span><FontAwesomeIcon icon={faChevronRight} /></span></a></li>
                )}
            </ul>
            <a href="/resources/topics" className="btn btn--tertiary">See All Topics <span><FontAwesomeIcon icon={faChevronRight} /></span></a>
        </section>
    )
}

export default BrowseBox